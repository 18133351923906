import { Stack, Typography } from '@mui/material'
import Head from 'next/head'
import Container from '../src/components/container'
import PageHeader from '../src/components/page-header'
import Section from '../src/components/section'

export default function Error404() {
  return (
    <>
      <Head>
        <title>Madison Square Garden -- Request Tix -- Page not found</title>
      </Head>
      <Container>
        <Section>
          <PageHeader title="404" />
          <Section variant="tertiary">
            <Stack spacing={3}>
              <Typography>Looks like this page does&apos;t exist. </Typography>
            </Stack>
          </Section>
        </Section>
      </Container>
    </>
  )
}
